<template>

    <table class="table">
        <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Last Update<br> (In device's timezone)</th>
            <th scope="col">Name</th>
            <th scope="col">IMEI</th>
            <th scope="col">Altitude</th>
            <th scope="col">Timezone</th>
            <th scope="col">Greek Name</th>
            <th scope="col">English Name</th>
            <th scope="col">Auto GPS</th>
            <th scope="col">Lat</th>
            <th scope="col">Lng</th>
            <th scope="col">Battery</th>
            <!-- <th scope="col">Third Party Platform</th>-->
            <th scope="col"> Actions </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(device,i) in this.devices">
            <tr v-for="(device_credential,j) in device.credentials.filter(c => c['weather_service'] === $props.weather_service)" >
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ device_credential.last_time_sent_data ?
                    convertToTimezoneTime(device_credential, device)
                    : '--'}}</td>

                <td>
                    <div v-show = "device.edit.name == false">
                        <label @dblclick = "device.edit.name = true; editDeviceCache=device.name;"> {{device.name}} </label>
                    </div>
                    <input v-focus v-if = "device.edit.name == true" v-model = "device.name"
                           v-on:blur= "editDevice(device, 'name')"
                           @keyup.enter = "editDevice(device, 'name')">
                </td>
                <td>
                    {{ device.imei }}
                    <!--                <div v-show = "device.edit.imei == false">-->
                    <!--                    <label @dblclick = "device.edit.imei = true; editDeviceCache=device.imei;"> {{device.imei}} </label>-->
                    <!--                </div>-->
                    <!--                <input v-focus v-if = "device.edit.imei == true" v-model = "device.imei"-->
                    <!--                       v-on:blur= "device.edit.imei=false; editDevice(device, 'imei')"-->
                    <!--                       @keyup.enter = "device.edit.imei=false; editDevice(device, 'imei')">-->
                </td>
                <td>
                    <div v-show = "device.edit.altitude == false">
                        <label @dblclick = "device.edit.altitude = true; editDeviceCache=device.altitude;"> {{ device.altitude }} </label>
                    </div>
                    <input v-focus v-if = "device.edit.altitude == true" v-model = "device.altitude" type="number" step=".01"
                           v-on:blur= "editDevice(device, 'altitude')"
                           @keyup.enter = "editDevice(device, 'altitude')">
                </td>
                <td>
                    <select v-model="device.timezone" class="py-0" @change="editDevice(device, 'timezone')">
                        <option v-for="timezone in timezones" v-bind:value="timezone">
                            {{ timezone }}
                        </option>
                    </select>
                </td>
                <td>
                    <div v-show = "device.edit.greek_name == false">
                        <label @dblclick = "device.edit.greek_name = true; editDeviceCache=device_credential.greek_name;"> {{ device_credential.greek_name }} </label>
                    </div>
                    <input v-focus v-if = "device.edit.greek_name == true" v-model = "device_credential.greek_name"
                           v-on:blur= "editCredentials(device, device_credential, 'greek_name')"
                           @keyup.enter = "editCredentials(device, device_credential, 'greek_name')">
                </td>
                <td>
                    <div v-show = "device.edit.english_name == false">
                        <label @dblclick = "device.edit.english_name = true; editDeviceCache=device_credential.english_name;"> {{ device_credential.english_name }} </label>
                    </div>
                    <input v-focus v-if = "device.edit.english_name == true" v-model = "device_credential.english_name"
                           v-on:blur= "editCredentials(device, device_credential, 'english_name')"
                           @keyup.enter = "editCredentials(device, device_credential, 'english_name')">
                </td>
                <td >
                    <input type="checkbox" v-model="device_credential.auto_refresh_coords" style="width: 20px;" v-on:click="toggleAutoGPS(device_credential)">
                </td>
                <td>
                    <div v-show = "device.edit.lat == false || device_credential.auto_refresh_coords == true">
                        <label @dblclick = "device.edit.lat = true; editDeviceCache=device_credential.lat;"> {{ device_credential.lat }} </label>
                    </div>
                    <input v-focus v-if = "device.edit.lat == true && device_credential.auto_refresh_coords == false" v-model = "device_credential.lat"
                           v-on:blur= "editCredentials(device, device_credential, 'lat')"
                           @keyup.enter = "editCredentials(device, device_credential, 'lat')">
                </td>
                <td>
                    <div v-show = "device.edit.lng == false || device_credential.auto_refresh_coords == true">
                        <label @dblclick = "device.edit.lng = true; editDeviceCache=device_credential.lng;"> {{ device_credential.lng }} </label>
                    </div>
                    <input v-focus v-if = "device.edit.lng == true && device_credential.auto_refresh_coords == false" v-model = "device_credential.lng"
                           v-on:blur= "editCredentials(device, device_credential, 'lng')"
                           @keyup.enter = "editCredentials(device, device_credential, 'lng')">
                </td>
                <td :class="'battery_' + device.id">
                    {{ device.last_battery_level }} %
                </td>
                <td style="white-space: nowrap;">
                    <!--                <button @click="checkAgensoServicesConnection(device)" type="button" class="btn btn-primary">-->
                    <button @click="openmodal(device)" type="button" class="btn btn-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </button>
                    <button @click="deleteCred(device_credential)" type="button" class="btn btn-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                        </svg>
                    </button>
                </td>
            </tr>
        </template>
        </tbody>
    </table>

</template>
<script>
export default {
    props : [ 'timezones', 'weather_service' ,'showModal'],
    data() {
        return {
            editDeviceCache: "",
            devices: [],
            timer: "",
        }
    },
    mounted() {
        this.$root.$on('refresh', c => { this.fetchData(); } )
    },
    created() {
        this.fetchData();
        this.timer = setInterval(this.fetchData,
            600000); // 10 minutes in milliseconds
    },
    methods: {
        fetchData() {
            this.axios
                .get('/devices')
                .then(response => {
                    // console.log(response.data.filter(device => device['credentials'][0]['weather_service'] === this.weather_service) );
                    response.data.forEach(device => {
                        device.edit = {
                            'name' : false,
                            'imei' : false,
                            'english_name' : false,
                            'greek_name' : false,
                            'lat' : false,
                            'lng' : false,
                            'altitude' : false,
                        };
                    });
                    this.devices = response.data;
                    console.log(this.devices);
                })
        },
        toggleAutoGPS(device_credential){
            console.log(device_credential);
            this.axios
                .post('/gps/toggle/' + device_credential.id)
                .then(response => {
                    if (response.data.wasSuccessful == true) {
                        this.$toastr.s("Success", response.data.message);
                    } else {
                        this.$toastr.e("Error", response.data.message);
                    }
                    this.$root.$emit('refresh');
                })
        },
        openmodal(device) {

            this.axios
                .get('/latest_measurement/' + device.id + '?weather_service=' + this.weather_service)
                .then( response => {
                    if(response.data.success){
                        this.$parent.showModal = true;
                        this.$parent.imei = device.imei
                        this.$parent.device_id = device.id;
                        this.$parent.last_live_measurement = response.data.last_live_measurement;
                        this.$parent.last_received_measurement = response.data.last_received_measurement;
                        this.$parent.weather_service_updated = response.data.weather_service_updated;
                        this.$parent.send_status_email = response.data.send_status_email;
                        this.$parent.battery = response.data.battery;
                    }else{
                        alert('There was an error')
                    }

                });
        },
        checkAgensoServicesConnection(device) {
            this.axios
                .get('/checkAgensoServices/' + device.id)
                .then( response => {

                    if (response.data.wasSuccessful == true) {
                        this.$toastr.s(response.data.message, "Success");
                    } else {
                        this.$toastr.e(response.data.message, "Error");
                    }
                });
        },
        convertToTimezoneTime( credential, device ) {
            let year = credential.last_time_sent_data.substr(0,4);
            let month = credential.last_time_sent_data.substr(5,2) - 1;
            let day = credential.last_time_sent_data.substr(8,2);
            let hours = credential.last_time_sent_data.substr(11,2);
            let minutes = credential.last_time_sent_data.substr(14,2);
            let seconds = credential.last_time_sent_data.substr(17,2);
            let date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
            return date.toLocaleString("de-De", { timeZone : device.timezone })
        },
        cancelAutoUpdate() {
            clearInterval(this.timer);
        },
        editCredentials(edit_device, device, attribute) {
            if(edit_device.edit[attribute] == false){
                return;
            }
            edit_device.edit[attribute] = false;
            if (device[attribute].trim() == "") device[attribute] = this.editDeviceCache;
            // device.edit[attribute] = false;

            this.axios
                .put('/creds/' + device.id, device)
                .then(response => {
                    console.log(response);
                    if (response.data.wasSuccessful == true) {
                        this.$toastr.s("Success", response.data.message);
                    } else {
                        this.$toastr.e("Error", response.data.message);
                    }
                    this.$root.$emit('refresh');
                })

        },
        editDevice(device, attribute) {
            if(device.edit[attribute] == false){
                return;
            }
            device.edit[attribute] = false;
            if (device[attribute].trim() == "") device[attribute] = this.editDeviceCache;

            this.axios
                .put('/devices/' + device.id, device)
                .then(response => {
                    console.log(response);
                    if (response.data.wasSuccessful == true) {
                        this.$toastr.s("Success", response.data.message);
                    } else {
                        this.$toastr.e("Error", response.data.message);
                    }
                    this.$root.$emit('refresh');
                });
        },
        deleteCred(cred) {

            Vue.$confirm({
                title: 'Are you sure?',
                message: "Are you sure you want to delete these credentials?",
                button: {
                    yes: 'Yes',
                    no: 'Cancel'
                },
                callback: confirm => {
                    if (confirm) {
                        this.axios
                            .delete('/creds/' + cred.id)
                            .then(response => {
                                console.log(response);
                                if (response.data.wasSuccessful == true) {
                                    this.$toastr.s("Success", response.data.message);
                                } else {
                                    this.$toastr.e("Error", response.data.message);
                                }
                            })
                            .finally(response => {
                                setTimeout(() => {  this.fetchData(); }, 1000);
                            })
                    }
                }
            })

        }
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus();
            }
        }
    }
}
</script>
<style scoped>
td {
    text-align: -moz-center;
}
th {
    text-align: -moz-center;
}
label {
    max-width: 150px;
    word-wrap: break-word;
}
#tagDropdown  label {
    display: block ruby;
    padding-right: 10px;
    padding-left: 11px;
    margin-bottom: 0;
}
.filterListItem {
    width:25px;
    height:20px;
    display:inline;
}
.tag {
    display: inline;
    cursor: pointer;
    margin:0 0 0 0;
    width:25px;
    height:20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.container {
    min-height: 300px;
    display: flex;
    flex-direction: column;
}
footer {
    padding: 8px 15px;
    background: #17ace3;
    border-radius: 3px;
    color: white;
}

.input__div {
    margin: 6px 0;
    position: relative;
    border: 1px solid #e4f5ef;
    flex: 1;
}
.input__div:focus-within .input__wrapper {
    background: #fff;
}
.input__div .input__wrapper {
    background: #f7fcfa;
    padding: 0 15px;
    transition: background 0.3s;
}
.input__div .input__wrapper img {
    opacity: 0.7;
}
.input__div:focus-within .input__wrapper img {
    opacity: 1;
}
.input__div .input__wrapper input {
    height: 35px;
    background: 0 0;
    border: none;
    color: #2c3e50;
    display: block;
    font-family: inherit;
    font-size: 16px;
    line-height: 16px;
    outline: 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 1;
}
.input__div .border {
    background: #42b983;
    transition: all 0.18s;
    bottom: -1px;
    height: 2px;
    left: 30%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 30%;
}
.input__div:focus-within .border {
    left: 0;
    right: 0;
    opacity: 1;
}
.material-checkbox {
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
}
.material-checkbox > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    z-index: -1;
    left: -5px;
    top: -5px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.42);
    outline: none;
    opacity: 0;
    transform: scale(1);
    -ms-transform: scale(0); /* Graceful degradation for IE */
    transition: opacity 0.5s, transform 0.5s;
}
.material-checkbox > input:checked {
    background-color: #2196f3;
}
.material-checkbox > input:disabled {
    opacity: 0;
}
.material-checkbox > input:disabled + span {
    cursor: initial;
}
.material-checkbox > span::before {
    content: "";
    display: inline-block;
    margin-right: 15px;
    border: solid 2px rgba(0, 0, 0, 0.42);
    border-radius: 2px;
    width: 14px;
    height: 14px;
    vertical-align: -4px;
    transition: border-color 0.5s, background-color 0.5s;
}
.material-checkbox > input:checked + span::before {
    border-color: #41b883;
    background-color: #41b883;
}
.material-checkbox > input:active + span::before {
    border-color: #41b883;
}
.material-checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.42);
}
.material-checkbox > span::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 10px;
    border: solid 2px transparent;
    border-left: none;
    border-top: none;
    transform: translate(5.5px, 1px) rotate(45deg);
    -ms-transform: translate(5.5px, 2px) rotate(45deg);
}
.material-checkbox > input:checked + span::after {
    border-color: #fff;
}
input[type="radio"] {
    cursor: pointer;
}
input, select, textarea {
    width: 190px;
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;
    -webkit-box-sizing:content-box;
}
input {
    width: 218px;
}
</style>
