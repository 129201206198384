<template>
    <div  v-if="showModal" >
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper" >
                    <div class="modal-dialog modal-sm" role="document" >
                        <div class="modal-content">
                            <div class="modal-header"  style="text-align: left;">
                               {{ imei }}
                            </div>

                            <div class="modal-body" style="text-align: left;">
                                <input id="modal_device_id" value="0" type="hidden">
                                <div class="row">
                                    <div class="col-5 col-md-5 col-sm-12" style="margin-bottom: 15px;">
                                        <button @click="resubscribe" type="button" class="btn btn-success">Resubscribe</button>
                                    </div>
                                    <div class="col-4 col-md-5" style="margin-bottom: 5px;">
                                        <button @click="reset" type="button" class="btn btn-danger">Reset</button>
                                    </div>

                                </div>
                                <div class="row" >
                                    <div class="col-12" style="margin-left: 20px;margin-bottom: 15px;">
                                        <input class="form-check-input" v-on:change="send_alert_option()" type="checkbox" id="chk_send_alert" v-model="send_status_email">
                                        <label class="form-check-label" for="chk_send_alert">
                                            Health check email
                                        </label>
                                    </div>
                                </div>
                                <hr><br/>
                                <span style="font-size: 14px;"><b>- Latest live measurement</b></span><br/>
                                <span style="font-size: 14px;padding-left:15px;">{{last_live_measurement}}</span><br/>
                                <span style="font-size: 14px;"><b>- Latest received measurement</b></span><br/>
                                <span style="font-size: 14px;padding-left:15px;">{{last_received_measurement}}</span><br/>
                                <span style="font-size: 14px;"><b>- Weather service updated</b></span><br/>
                                <span style="font-size: 14px;padding-left:15px;">{{weather_service_updated}}</span><br/>
                                <span style="font-size: 14px;"><b>- Battery Level</b></span><br/>
                                <span style="font-size: 14px;padding-left:15px;">{{battery}} %</span><br/>

                            </div>
                            <div class="modal-footer">
                                <button type="button" @click="close" class="btn btn-primary">OK</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "ControlModal",
    props:[
        'showModal',
        'device_id',
        'last_live_measurement',
        'last_received_measurement',
        'weather_service_updated',
        'send_status_email',
        'imei',
        'battery'
    ],
    methods:{
        send_alert_option: function () {
            this.axios
                .post('/settings/' + this.device_id)
                .then( response => {
                    console.log('success');
                });
        },
        close:function (event){
            event.preventDefault();
            $('.battery_' + this.device_id).html(this.battery + ' %')
            this.$parent.showModal = false;
        },
        resubscribe: function (){
            this.axios
                .get('/resubscribe/' + this.device_id)
                .then( response => {
                    this.close(event)
                });
        },
        reset: function (){
            if(confirm("WARNING! This action should be done only when node is out of order. In case of reset, node will need some time to start working again. Are you sure you want to reset this node?")){
                this.axios
                    .get('/reset/' + this.device_id)
                    .then( response => {
                        this.close(event)
                    });
            }
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-body {
    max-height: calc(90vh - 100px);
}

.modal-dialog {
    margin: 20vh auto 0px auto
}

</style>
