require('./bootstrap');
window.Vue = require('vue').default;

import Vue from "vue";
import VueToastr from "vue-toastr";
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueConfirmDialog from 'vue-confirm-dialog';
Vue.use(VueAxios, axios);
Vue.use(VueConfirmDialog);
Vue.use(VueToastr, {});

// Vue.component('vue-toastr', VueToastr);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('credentials-table', require('./components/CredentialsTable.vue').default);
Vue.component('devices-panel', require('./components/DevicesPanel.vue').default);

const app = new Vue({
    el: '#app',
});
